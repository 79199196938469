export const dropups = [
  {
    value: "Academics",
    index: 0,
    fields: [
      "Branches",
      "CGPA",
      "Branch Change",
      "Scholarships",
      "NPTEL",
      "Research",
      // "First Semester",
      "PG and PhD",
    ],
  },
  {
    value: "Co-Curricular",
    index: 1,
    fields: [
      "Cultural Groups",
      "Technical Groups",
      "Sports",
      "Inter-IIT",
      "NCC-NSS-NSO",
    ],
  },
  {
    value: "Campus",
    index: 2,
    fields: ["Hostels", "Places", "Covid Measures", "Eateries"],
  },
  {
    value: "Student's Corner",
    index: 3,
    fields: [
      "To-do List",
      "Packing List",
      "IITR Lingo",
      "Commute and Payments",
      "FAQs",
    ],
  },
  { value: "Experiences", index: 4, fields: ["Freshman", "Archive"] },
  { value: "Placements", index: 5, fields: ["Analysis"] },
  {
    value: "Parents' Section",
    index: 6,
    fields: ["Anti-Ragging Policy", "Banking", "Where to Stay", "Security"],
  },
]

export const carouselPic = [
  {
    link: "Academics",
    content: "Read about academic structure and branch change at IITR here.",
    name: "main2",
    to: "/academics/branch-change",
  },
  {
    link: "Groups",
    content:
      "The R-Land has a rich group culture, read about the various campus groups here.",
    name: "mgcl",
    to: "/co-curricular/cultural-groups/",
  },
  {
    link: "Branches",
    content:
      "IIT-R has some of the oldest departments in the country, read about them here.",
    name: "ecetower",
    to: "/academics/branches",
  },
  {
    link: "Places",
    content:
      "Roorkee is filled with exciting places and famous spots, read about them here",
    name: "main-drone",
    to: "/campus/places",
  },
]

export const connect = [
  {
    url: "https://www.facebook.com/geekgazette",
    image: "facebook.png",
  },
  {
    url: "https://www.instagram.com/geek_gazette/",
    image: "insta_exp.png",
  },
  {
    url: "https://www.linkedin.com/company/geek-gazette/",
    image: "linkedin_exp.png",
  },
  {
    url: "https://twitter.com/teamgeekgazette",
    image: "tweet_exp.png",
  },
]

export const h2data = [
  {
    title: "Campus Groups",
    content:
      "Engineering shouldn’t be limited to academics, and those with a passion to learn new things...",
    src:
      "https://cdn.dribbble.com/users/1036545/screenshots/2491401/iitr_dribbble.png",
    link: "co-curricular/technical-groups",
  },
  {
    title: "Hostels",
    content:
      "Bhawans! That is how we define our dwelling places on the sprawling IIT campus. These bhawans are...",
    src:
      "https://cdn.dribbble.com/users/1036545/screenshots/2491401/iitr_dribbble.png",
    link: "campus/hostels",
  },
  {
    title: "Eateries",
    content:
      "Food brings people together on different levels. It’s nourishment of the soul and body, it’s truly love.",
    src:
      "https://cdn.dribbble.com/users/1036545/screenshots/2491401/iitr_dribbble.png",
    link: "campus/eateries",
  },
]

export const testimonialExcerpt = [
  {
    text:
      "I have school in the morning, coaching classes in the evening, and a shit ton of assignments to complete. But I don’t study, I roam around the hallway...",
    author: "Prabhav Makkar",
    branch: "Chemical Engineering, IITR’23",
    source: "gg_logo.png",
  },
  {
    text: `Happy families are all alike, every unhappy family is unhappy in its own way" - wrote Leo Tolstoy in Anna Karenina. The more the strides a person...`,
    author: "Gajanan Gitte",
    branch: "Computer Science Engineering, IITR’23",
    source: "gg_logo.png",
  },
  {
    text:
      "The day had finally come. I was going to join one of the premier historical institutions of our country - IIT Roorkee. After researching almost everything...",
    author: "Natansh Mathur",
    branch: "Computer Science Engineering, IITR’21",
    source: "gg_logo.png",
  },
]

export const homeColumn = [
  {
    heading: "Student’s Corner",
    body:
      "<i>“The one you should read”</i><br>This section comprises all the things you’ll need to know before coming to Roorkee, including necessities like what to pack and the lingo encountered here.",
    to: "/student's-corner/to-do-list/",
    isReverse: false,
    backdrop: "tr",
    image: "home/students.png",
  },
  {
    heading: "Parents Corner",
    body:
      "<i>“The one for Mom and Dad”</i><br>This segment is dedicated to informing the guardians of their various requirements. It also includes details regarding acquiring a guest room when you’re visiting.  ",
    to: "/parents'-section/anti-ragging-policy/",
    isReverse: true,
    backdrop: "tl",
    image: "home/parents.png",
  },

  {
    heading: "Branch Change",
    body:
      "<i>“The one you were looking for”</i><br>This section provides you with the necessary preliminary equipment and information required to commence your (not so) adventurous and dangerous quest towards a Branch Change.<br>May you fare well on your journey!",
    to: "/academics/branch-change/",
    isReverse: false,
    backdrop: "tr",
    image: "home/branch-change.png",
  },
  {
    heading: "Placement Analysis",
    body:
      "<i>“The one with the numbers”</i><br>Here lies a comprehensive analysis of the placement season in IIT Roorkee, including tantalizing statistical figures and recent trends in recruitments.",
    to: "/placements/analysis/",
    isReverse: true,
    backdrop: "tl",
    image: "home/placement.png",
  },
]
