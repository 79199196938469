import React, { Component, useState } from "react"
import "../styles/mobilenavbar.css"
import { dropups } from "../data/global"
import { Link } from "gatsby"
import { Accordion, Icon } from "semantic-ui-react"
export default class MobileNavBar extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }
  render() {
    const { activeIndex } = this.state
    return (
      <div className="mnav-cntr" id="mobilenav">
        <div className="mnav-dropups">
          {dropups.map((dropup, index) => (
            <Accordion className="mnav-ac-cntr">
              <Accordion.Title
                active={activeIndex === dropup.index}
                index={dropup.index}
                onClick={this.handleClick}
                className="mnav-title"
              >
                {dropup.value}
                <Icon name="dropdown" size="tiny" />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                {dropup.fields.map(field => (
                  <Link
                    to={`/${dropup.value
                      .split(" ")
                      .join("-")
                      .toLowerCase()}/${field
                      .split(" ")
                      .join("-")
                      .toLowerCase()}/`}
                  >
                    <div className="mnav-field">{field}</div>
                  </Link>
                ))}
              </Accordion.Content>
            </Accordion>
          ))}
        </div>
      </div>
    )
  }
}
