import React from "react"
import { Dropdown, Menu, Image } from "semantic-ui-react"
import "../styles/navbar.css"
import { dropups } from "../data/global"
import { Link } from "gatsby"
import ResponsiveComponent from "./responsive-component"
import MobileNavBar from "./mobilenavbar"
const MenuList = () => (
  <Menu
    stackable
    widths={7}
    size="small"
    borderless
    className="navbar-menu"
    attached={"bottom"}
  >
    {dropups.map((dropup, index) => (
      <Dropdown item text={dropup.value} className={"box"}>
        <Dropdown.Menu className="dd-menu">
          {dropup.fields.map(field => (
            <Dropdown.Item
              className="ditem"
              value={index}
              as={Link}
              to={`/${dropup.value
                .split(" ")
                .join("-")
                .toLowerCase()}/${field.split(" ").join("-").toLowerCase()}/`}
            >
              {field}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ))}
  </Menu>
)
export default class Navbar extends ResponsiveComponent {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, toggle: false }
  }
  componentDidMount() {
    if (window) {
      this.setState({ windowWidth: window.innerWidth })
    }
  }
  handleClick = () => {
    this.setState({ toggle: !this.state.toggle })
  }
  handleClick2 = () => {
    this.setState({ toggle: false })
  }
  render() {
    return (
      <div className="navbar-container">
        <div className="navbar-header">
          <div className="above-menu">
            <Image
              src={require("../images/icons/explogo.jpg")}
              fluid
              size={"small"}
              as={Link}
              onClick={this.handleClick2}
              to="/"
            />
            <Image
              src={require("../images/icons/hamburger.png")}
              fluid
              onClick={this.handleClick}
              className="cond-icon"
            />
          </div>
          {/* {this.state.toggle ||
          (typeof window !== "undefined" && window.innerWidth) > 1024 ? (
            <MenuList />
          ) : (
            // { MobileBar }
            this.state.toggle
          )} */}
          {typeof window !== "undefined" && window.innerWidth > 1024 ? (
            <MenuList />
          ) : (
            // <MobileNavBar />
            ""
          )}
          {this.state.toggle &&
          typeof window !== "undefined" &&
          window.innerWidth <= 1024 ? (
            <MobileNavBar />
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}
