import { Component } from "react"
class ResponsiveComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null }
  }
  componentDidMount() {
    if (window) {
      window.addEventListener("resize", this.handleResize)
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    })
  }
}

export default ResponsiveComponent
